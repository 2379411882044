import { Location, navigate } from '@reach/router';
import { Button, Spin } from 'antd';
import qs from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import SiteLayout from '../components/SiteLayout';

// @ts-ignore
import Terms from '../../static/terms.inline.svg';

// @ts-ignore
import { backbutton, iframe, image } from './identifiser.module.scss';

import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { Link } from 'gatsby';

import { IndentificationStatus, SessionStatus } from '../service';
import { useTrackedSessionStoreStore } from "../state/sessionStore";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export function IdentifiserComponent({ isEmbeded }) {

    const iframeRef = useRef();
    const [loading, setLoading] = useState<boolean>(true);
    const [authUrl, setAuthUrl] = useState<string>("");
    // const [_authUrl] = useGlobalState('authUrl');
    //const [_sessionId] = useGlobalState('sessionId');

    const sessionStore = useTrackedSessionStoreStore();

    useEffect(() => {
        doCheck();
    }, []);

    useEffect(() => {
        window.addEventListener('message', onReceiveMessage);
        return () => window.removeEventListener("message", onReceiveMessage);
    }, []);

    const onReceiveMessage = (event) => {
        console.log('onReceiveMessage', event);
        let { origin, data } = event;

        if (data.done) {
            console.log("We are done");
            updateCode(data.code);
        }
    };

    async function updateCode(code) {
        const parsed = qs.parse(location.search);
        const result = await sessionStore.code({ sessionId: parsed.sid as string, code });
        let link = "";
        isEmbeded ? link = "/embeded/valider?sid=" + parsed.sid : link = "/valider?sid=" + parsed.sid;
        navigate(link);
    }

    async function doCheck() {
        const parsed = qs.parse(location.search);
        const result = await sessionStore.check({ sessionId: parsed.sid as string });
        let embeded = "";
        isEmbeded ? embeded = "/embeded" : embeded = "/";

        // sessionStatus = created and identifcation status is pending
        if (result.sessionStatuses === SessionStatus.NUMBER_1 && result.indentificationStatuses === IndentificationStatus.NUMBER_1) {
            setLoading(false);
            setAuthUrl(sessionStore.authUrl);
        } else {
            navigate(embeded);
        }
    }

    return (
        <>
            {loading ? <div style={{ marginBottom: 20 }}><Spin indicator={antIcon} /></div> :
                <iframe ref={iframeRef} className={iframe} src={authUrl} frameBorder="none"></iframe>
            }
        </>
    );
}

function Identifiser() {
    return (<Location>
        {({ location, navigate }) => (
            <SiteLayout location={location}>
                <Terms className={image} />
                <br />
                <h1>Aktiver HAN</h1>
                <p style={{ fontWeight: 400 }}>Identifiser deg slik at vi vet hvem du er</p>
                <br />
                <IdentifiserComponent isEmbeded={false} />
                <Link to="/velg"><Button icon={<ArrowLeftOutlined />} className={backbutton}>Tilbake</Button></Link>
            </SiteLayout>
        )}
    </Location>);
}

export default Identifiser;
